.payment-title {
  min-width: 30%;
}

#paymentInterval {
  input {
    margin-top: 6px;
    border-radius: 0;
    height: 26px;
  }

  .add-on {
    margin-top: -2px;
    height: 26px;
    font-size: 11px;
    line-height: inherit;
  }

  span.add-on {
    color: #aaa;
  }
}

.stats {
  padding-bottom: 20px;
  min-height: 205px;
  
  #chartPayments {    
    height: 180px;
  }
  
  .chart {    
    min-height: 170px;
    overflow-x: auto;
  }

  .stat_tables {
    margin-left: 20px;
    font-size: 15px;
    width: 170px;
    
    td:first-child {
        width: 40%;
      }
    tfoot td {
      padding-top: 15px;
    }
  }  
}

.datepicker-dropdown {
  margin: 1px 0px 0px;
}

.userInfo {
  color: #337ab7;
  cursor: pointer;
}

.formUserInfo [disabled] {
  background-color: #fff;
}

