.ingredient-container{
    position: relative;
    width: 50%;
    padding: 0 15px;
}
.input-label{
    padding: 8px 4px;
}
.row-title{
    padding-top: 5px;
}
#confirmDelete{
    z-index: 100000;  
    padding-top: 50px;
}
.star-name:after {
    right: 5px !important;
}

.ingredient-container .star-sign:after {
    right: -6px;
}
.empty-message{
   font-family: sans-serif !important;
   font-size: 13px !important;
    padding-left: 3px;
}
.ui-autocomplete-input{
   font-family: sans-serif !important;
   font-size: 14px !important;
   line-height: normal;
   
   height: 30px;
   padding: 0px 5px !important;
   z-index: 100;
   border-radius: 4px;
   border: 1px solid #ccc !important;
   margin: 1px 0 !important;
}
.ui-state-hover {
    background-color: #3875d7 !important;
    background-image: linear-gradient(#3875d7 20%, #2a62bc 90%) !important;
    color: #ffffff !important;
}

.ui-state-hover, 
.ui-widget-content .ui-state-hover, 
.ui-widget-header .ui-state-hover, 
.ui-state-focus, 
.ui-widget-content .ui-state-focus, 
.ui-widget-header .ui-state-focus{
   background-color: #3875d7 !important;
   background-image: linear-gradient(#3875d7 20%, #2a62bc 90%) !important;
   color: #ffffff !important;
}

ul.ui-autocomplete {
    color: #444;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    
    li {
        margin: 0;
        padding: 5px 6px;
        list-style: none;
        line-height: 15px;
        word-wrap: break-word;
    }
}

.trans-ingred{  
    width: 100%;
    tr{        
        td:first-child{
            text-align: right;
            padding-right: 25px;
            width: 55%;
        }
    }
}