.market-create, #listMarkets{
    .radio input[type="radio"]{
        margin-left: 0px !important;        
        label{
            padding-left: 5px;
        }
    }
    .btn-cancel{
        padding-left: 15px;
        padding-right: 15px;
    }
}
.row-hover {
  cursor: pointer
}
.localeValue{
       display: inline;
}
.dropdownLang, .dropdownMarkets{
    width: 150px !important;
    position: relative;
    display: inline-block; 
    cursor: pointer;
}

.locale-box {
    position: relative;
}

.star-market {
    position: absolute;
    left: 264px;
}

#selectNewMarket{
    .content-txt{
        padding: 0 15px;
        
        .dropdownMarkets{
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}
.form-group-wrapper{
    width: 31%;
    position: relative;
    .star-sign-default:after{
        top: 11px;
    }
}
.btnCreateMarket{
    width: initial;
}
