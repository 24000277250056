#formCreateUser {
  margin-top: 20px;
  .css-init-width{
    width: initial;
  }
}

.logged-as {
  margin: 15px;
}

.cbUsersChosen.chosen-container {
  width: 100% !important;
}

.cbUserMarkets .chosen-choices li.search-field input[type=text] {
  min-width: 140px !important;
}

.cbUserMarkets {
  height: auto !important;
}
