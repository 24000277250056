.row-trans-name {
    float: left;
    width: auto !important;
    padding-right: 15px;
}

.treeview {
    padding-bottom: 30px;
}

.fixed {
    position: fixed;
    top: 10px;
    z-index: 9;
}