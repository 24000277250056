@import 'bower_components/bootstrap/less/bootstrap.less';
@import (less) 'bower_components/chosen/chosen.min.css';
@import (less) 'bower_components/jquery-ui/themes/smoothness/jquery-ui.min.css';
@import 'bower_components/bootstrap-datepicker/build/build.less';

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

.button {
  background: grey;
}

body > .container-fluid {
  padding-bottom: 20px;
}

.footer {
  background-color: #f5f5f5;
}

// Hide pagination elements because we use infinite scroll
.pagination {
  height: 0;
  opacity: 0;
}

#confirmAction {
  z-index: 1100;
}

@import 'custom-typo';

@import "custom-users";
@import "custom-buttons";
@import "custom-recipes";
@import "custom-markets";
@import "custom-ingredients";
@import "custom-tags";
@import "custom-payments";
@import "custom-exercises";
