h1:first-of-type {
  margin-top: 0;
}
.padding-right-15{
    padding-right: 15px;
}

panel-filter{
    min-width: 1400px; width: 100%;
}

.container-fluid > .alert {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 999;
}

.clearFilters {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
}

.chosen-container {
  width: 100% !important;
  font-size: 14px !important;
  padding: 4px 7px !important;
}

.chosen-container-single .chosen-drop {
  border: 1px solid #ccc !important;
}

.chosen-container-single .chosen-single, .cbUserMarkets ul {
  border: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
}



.panel-filter{
    width: 100%;
    .panel-body div.pull-left:first-child{
        width: 18% !important;       
        min-width: 200px !important;
    }
}

form[data-module~=users] .panel-filter{
    min-width:1090px; 
    width: 100%;
    
    .panel-body div.pull-left:first-child{
        width: 18% !important;  
        min-width: 230px !important;
    }   
    
    .panel-body div.pull-left:nth-child(2){
        width: 12% !important;
        min-width: 155px !important;
    } 
    .panel-body div.pull-left:nth-child(3){
        width: 149px !important;
    } 
    .panel-body div.pull-left:nth-child(4){
        width: 150px !important;
    } 
    .panel-body div.pull-left:nth-child(5){
        width: 138px !important;
    } 
    .panel-body div.pull-left:nth-child(6){
        width: 105px !important;
    } 
}

form[data-module~=recipes] .panel-filter{    
    min-width:915px; 
    width: 100%;
    
    .panel-body div.pull-left:first-child{
        width: 18% !important;  
        min-width: 230px !important;
    }   
    
    .panel-body div.pull-left:nth-child(2){
        width: 12% !important;
        min-width: 155px !important;
    } 
    .panel-body div.pull-left:nth-child(3){
        width: 150px !important;
    }    
    .form-control-static{
        padding-top: 0px; 
        padding-bottom: 0px; 
    }
}
form[data-module~=recipe_tags] .panel-filter{ 
    min-width:700px; 
    .form-control-static{
        padding-top: 0px; 
        padding-bottom: 0px; 
    }
}

#formFilters .col-xs-3{
    padding-left: 0px;
    width: 18%;
}

#ddMarketlist_chosen{
    min-width: 200px !important;
}
