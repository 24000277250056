#formCreateRecipe {
  margin-top: 20px;
}

tr[data-toggle="collapse"] {
  cursor: pointer;
}

.recipe-box {
  width: 50%;
}

.recipe-img-block {
  height: 148px;
}

.recipe-img-block img {
  border: 1px solid #ccc;
  padding: 5px;
}

.btnUploadImg {
  position: absolute;
  left: 15px;
}

.label-msg-upload, .label-msg-delete {
  position: absolute;
  top: 36px;
  left: 15px;
}

//.btnUploadImg[disabled], .btnDeleteImg[disabled] {
//  display: none;
//}

.btnUploadImg, .btnDeleteImg {
  width: 120px;
}

.recipe-categories-block {
  min-height: 70px;
  .checkbox {
    margin-top: 10px !important;
  }
}

.recipe-tags-block {
  min-height: 100px;
}

.is_scalable {
  padding-left: 5px;
}

.label-msg-upload, .label-msg-delete {
  color: #a94442;
}

.panel h2{
    display: inline;
}

.translate-origin-text {
  color: #ccc;
}

#modalRecipe {
  .glyphicon-question-sign {
    margin-left: 8px;
  }
}

  .tooltip-inner {
    width: 350px;
  }

  .star-sign:after, .star-sign-default:after {
    content:'\002A';
    position: absolute;
    right: 5px;
    top: 0;
  }

  .cbTags.chosen-container {
    height: auto;
    padding: 0 !important;
    border: 0;
  }

  .cbTags .chosen-choices {
    height: initial !important;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  .lb-tag-translate {
    font-weight: normal;
    line-height: 37px;
  }

  .box-lb-tag-translate {
    text-align: right;
  }

  .sortable {
    list-style-type: none;
    padding-left: 0px;
  }

  textarea[name="description"] {
    clear: both;
  }

  .input-li { bottom: 7px; }
  .sortable{
    li{
      height: 30px; margin: 10px 0; padding: 10px 1px;
    }
    .col-xs-3 {
      width: 22%;
      padding-left: 5px;
    }
    .cal-num{
      padding: 0 !important;
      margin: 0  !important;
      width: auto !important;
    }
    .calNum {
      padding-left: 0;
      padding-right: 2px;
      text-align: right;
    }
    .name{
      width: 31%;
      padding-right: 0;
      padding-left: 0;
      .name{
          width: 100%;
      }
    }
    .name, .calNum{
      div{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
      }
    }
  }

  .ui-autocomplete-input {
    border: none;
    font-size: 14px;
    width: 100%;
    height: 34px;
    margin-bottom: 5px;
    padding-top: 6px;
    border: 1px solid #DDD !important;
    z-index: 1511;
    position: relative;
  }
  .ui-menu .ui-menu-item a {
    font-size: 12px;
  }
  .ui-autocomplete {
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1051 !important;
    float: left;
    display: none;
    min-width: 160px;
    _width: 160px;
    padding: 4px 0;
    margin: 2px 0 0 0;
    list-style: none;
    background-color: #ffffff;
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
  }
  .ui-menu-item > a.ui-corner-all {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    color: #555555;
    white-space: nowrap;
    text-decoration: none;
  }
  .ui-state-hover, .ui-state-active {
    color: #ffffff;
    text-decoration: none;
    background-color: #0088cc;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    background-image: none;
  }

  .ddMeasures{
    width: 20% !important;
    padding-right: 6px;
  }
  div.amount{
    width: 20% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .btnCreateRecipe {
    width: 100%;
  }

  .steps-box {
    position: relative;
  }

  .star-steps {
    position: absolute;
    top: 7px;
    left: 77px;
  }

  .cbTags ul.chosen-results{
    z-index: 200;
  }

  #showCreateModal[disabled] {
    width: 110px;
  }

  .steps-empty {
    padding: 10px 27px;
    min-height: 200px;
    overflow-y: scroll;
  }

  .popover.right > .arrow:after {
    border-right-color: #000 !important;
  }

  .popover {
    .steps-empty, .popover-title {
      background-color: #000;
      color: #fff;
      border: 0;
      font-size: 12px;
      overflow: hidden;
      height: auto;
    }

    .popover-title {
      font-size: 14px;
      border-bottom: 1px #ccc solid;
    }

    .popover-content {
      color: #fff;
      min-width: 275px;
//      min-width: 175px;
    }

    .recipe_descr {
      font-size: 12px;
    }

    .popover-close {
      cursor: pointer;
      font-size: 13px;
      position: absolute;
      right: 13px;
      top: 10px;
    }

    pre {
      background-color: #000 !important;
      color: #fff;
      border: 0;
      font-size: 12px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
    }

    background-color: #000;
    opacity: 0.9 !important;
  }

  .popover-lg {
    width: 100%;
  }

  #formTranslateRecipe .glyphicon-info-sign {
    cursor: pointer;
  }

  #origDescr {
    font-size: 12px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  .empty-message {
    padding-top: 3px;

    label {
      font-weight: normal;
    }
  }

  #modalIngredient {
    z-index: 1045;
  }